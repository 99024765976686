
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { IExtensionType, defaultExtensionType } from './models/ExtensionType';

import entityService, { useBasicFilterEntity, useBasicFilterProperty, useBasicFilterQueryFeature } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom , isSaveLoadingAtom} from 'library/store';
import useExtensionTypeService, { useBasicFilterExtensionType } from './services/ExtensionType';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IEntityProperty, IQueryFeatureDescription, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, ClickAwayListener, FormControlLabel, IconButton, InputAdornment, Link, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import CodeIcon from '@mui/icons-material/Code';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import MoreIcon from '@mui/icons-material/More';

import { ListItemIcon } from '@mui/material';

import TextFieldRight from 'components/ui/TextFieldRight';

import { stripHtml } from "string-strip-html";

import { AiOutlineMessage, AiOutlineWhatsApp, AiOutlineFacebook, AiOutlineFileExcel, AiOutlineFileWord, AiOutlineLayout } from 'react-icons/ai';

import { HiOutlineDocumentReport } from 'react-icons/hi';

import { isFalsy } from 'utility-types';
import DocViewer, { PDFRenderer, PNGRenderer, MSDocRenderer ,DocViewerRenderers} from '@cyntler/react-doc-viewer';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useReportService, { useBasicFilterReport } from './services/Report';


import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { IReport, defaultReport, IReportField, IReportTable, IReportTableColumn, IReportTableDefaultParameter ,defaultReportDocument, ReportTableType } from './models/Report';

import { globalConfig } from 'config';
import { RichWysiwygEditor } from 'components/ui/RichWysiwygEditor';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';

import useEnumerationService from 'features/configuration/services/Enumeration';
import useApplicationQueryService, { useBasicFilterApplicationQuery } from 'features/setup/services/ApplicationQuery';

import { IEnumerationItem, Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT } from 'features/configuration/models/Enumeration';
import { IBusinessApplication, IApplicationQuery, IApplicationQueryParameter } from 'features/setup/models/ApplicationQuery';
//import * from '../../../public'

import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';
import { GrDocumentDownload } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';

import useUtils from 'library/utils';


export const ReportForm: FC<IReport> = (props: IReport = defaultReport) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {sanitizeFileName} = useUtils();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization, getQueryFeatureInnerObjectProperties } = entityService();

  const { createReport, updateReport, retriveReportDocumentFile } = useReportService();

  const { getApplicationQueryParameters } = useApplicationQueryService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();
  const {baseType } = useExtensionTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterReport = useBasicFilterReport( 
    (event: React.MouseEvent<unknown>, row: IReport) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openApplicationQueryFilter, setOpenApplicationQueryFilter] = useState(false);
    const basicFilterApplicationQuery = useBasicFilterApplicationQuery( 
      
      async (event: React.MouseEvent<unknown>, row: IApplicationQuery) => {
        const {id, name } = row;
        //setJobScheduleStepReportTableIndex(index);

        const {currentTableIndex} = getValues();
                
        if(currentTableIndex < 0) return;

        const reportTable = getValues().reportTables.at(currentTableIndex);
        
        if(isFalsy(reportTable)) return;
        
        const parameters = await getApplicationQueryParameters(id);
        
        (refUpdateReportTable.current??emptyFunc)(currentTableIndex, 
            {...reportTable, businessApplicationQueryId: id, businessApplicationQueryName: name,
              reportTableDefaultParameters: parameters.map( (param, idx) => ({
                parameterName: param.parameterName, parameterValueExpression: '', id: 0, 
                businessApplicationQueryParameterId: param.id
              }) )  });
                                
        setOpenApplicationQueryFilter(false);
      }
    );

  const emptyFunc = (obj: any) => {}
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          const {currentTableIndex} = getValues();

          if( isTableEntity ) {
            const reportTable = getValues().reportTables.at(currentTableIndex);
            
            if( !isFalsy(reportTable)) 
              (refUpdateReportTable.current??emptyFunc)(currentTableIndex, 
                {...reportTable, entityName: name, entityDescription: description });
            
          } else {
            setValue('entityName', name || '');      
            setValue('entityDescription', description || ''); 
          }
                
          setOpenEntityFilter(false);
      }, undefined , false
  );

  const [openEnumerablePropertyFilter, setOpenEnumerablePropertyFilter] = useState(false);
  const basicFilterEnumerableProperty = useBasicFilterProperty( 
    () => {      
        const {entityName} = getValues();
        return {entityName, enumerableOnly: true};               
    },
    async (event: React.MouseEvent<unknown>, row: IEntityProperty) => {
      const {name, description, underlyingType } = row;
            
      const {currentTableIndex} = getValues();

      if(name==='') return;

      const reportTable = getValues().reportTables.at(currentTableIndex);
      if(isFalsy(reportTable)) return;
     
      (refUpdateReportTable.current??emptyFunc)(currentTableIndex, 
          {...reportTable, 
              entityEnumerableProperty: name, 
              entityEnumerablePropertyDescription: description, 
              entityName: underlyingType });
                              
      setOpenEnumerablePropertyFilter(false);
    }
  );

  
  const [openQueryFeatureFilter, setOpenQueryFeatureFilter] = useState(false);
  const basicFilterQueryFeature = useBasicFilterQueryFeature( 
    () => {      
        const {entityName} = getValues();
        return {entityName};               
    },
    async (event: React.MouseEvent<unknown>, row: IQueryFeatureDescription) => {
      const {name, label, innerObjectTypeName, innerObjectPublicNamespace } = row;
            
      const {currentTableIndex} = getValues();
      if(name==='') return;

      const reportTable = getValues().reportTables.at(currentTableIndex);
      if(isFalsy(reportTable)) return;
     
      (refUpdateReportTable.current??emptyFunc)(currentTableIndex, 
          {...reportTable, 
            queryFeatureName: name, 
            queryFeatureDescription: label,
            entityName: 'BaseEntity', innerObjectPublicNamespace, innerObjectTypeName
             });
                              
            setOpenQueryFeatureFilter(false);
    }
  );
  
  
    
  const methods = useForm<IReport>({defaultValues:defaultReport});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields: reportDocuments, append: appendReportDocuments, update: updateReportDocument ,remove,  } = useFieldArray({ name: 'reportDocuments', control, });

  const watchCurrentDocumentLanguage = watch('currentDocumentLanguage');
  
  // const watchReportType = watch('type');
  // const watchCurrentMessageType = watch('currentMessageType');
  // const watchCurrentReportFileType = watch('currentReportFileType');

  const watchEntityName = watch('entityName');

  const [currentFieldIndex, setCurrentFieldIndex] = useState(-1);
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IReport>,Error,IReport>(
      _id>0?updateReport:createReport, {   
        onSuccess: (data: IResult<IReport>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Report',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

      const [enumItems, setEnumItems] = useState<IEnumerationItem[]>([]);
      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Report'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] ));

    const {data: _data, refetch} = useQuery<IReport>(['Report', _id], () => retrieveEntity('Report',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      // const handleClickSearchEnumeration = (event: any) => {
      //   setOpenEnumerationFilter(true);
      // } 

      const [currentInputFocus, setCurrentInputFocus] = useState('');
      const handleInputFocus = (event: any, prop: string , index:  number) => {      
        setCurrentInputFocus(prop);
      }

      const expressionRefShortMessage = useRef();
      const expressionRefMessageObject = useRef();
       
      const handleAliasDoubleClick = (event: React.MouseEvent<unknown>,index: number,row: IReportField) => {  
      
        if(index < 0) return;
        
        const {reportFields} = getValues();
        const reportField = reportFields.at(index);
        if(isFalsy(reportField)) return;

        const {name, alias} = reportField!;        
        
        var position = 0;
        var newExpression = '';
        var currentExpression = '';

        if( currentInputFocus === 'shortMessageBody' ) {
          position = (expressionRefShortMessage.current! as HTMLInputElement).selectionEnd || 0;
          currentExpression = (expressionRefShortMessage.current! as HTMLInputElement).value || '';          
        } else if( currentInputFocus === 'messageObject' ) {
          position = (expressionRefMessageObject.current! as HTMLInputElement).selectionEnd || 0;
          currentExpression = (expressionRefMessageObject.current! as HTMLInputElement).value || '';
        }

        
        newExpression = currentExpression.substring(0, position)  + '@${' +  `${alias}` + '}' + currentExpression.substring(position);
        
        const idx = getValues().reportDocuments.findIndex(d => d.documentLanguage === watchCurrentDocumentLanguage);
        
        if(idx < 0) return;

        const reportDocument = getValues().reportDocuments.at(idx)!;
        const messageObject = (currentInputFocus === 'messageObject') ? newExpression : reportDocument.messageObject;
        const shortMessageBody = (currentInputFocus === 'shortMessageBody') ? newExpression : reportDocument.shortMessageBody;

        updateReportDocument(idx, {...reportDocument, shortMessageBody, messageObject });        
    }

    const reportFieldRowActionIcon = ( reportField: IReportField) : ActionIconTableRow<IReport,IReportField> => {  
      const res: ActionIconTableRow<IReport,IReportField> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IReportField) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IReportField) => {           
          
          (refRemoveReportField.current??emptyFunc)(index);            
        }
      }
      return res;
    }
      
    const getInputAdornmentReportField = (row: IReportField, cellId: keyof IReportField)  => ({
 
      toolTip: 'Expression',
      icon: DeveloperModeIcon,
      onClickIcon: (event: any, index: number, row: IReportField ) => {
                        
        const {entityName, reportFields} = getValues();

        const reportField = reportFields.at(index);
        if(isFalsy(reportField)) return;

        const {valueExpression, format} = reportField!;

        if(isFalsy(entityName)) {
          enqueueSnackbar( t('Entity is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
        
        if(isFalsy(format)){
          enqueueSnackbar( t('Data format is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        

        const enumItemFormat = (refEnumItems.current || []).find(x => x.enumerationCode === Enum_REPORTFIELD_DATA_FORMAT && x.code === format);
        const returnType = baseType(enumItemFormat?.parentEnumerationItemCode);
        

        setCurrentFieldIndex(index);
        handleClickOpenExpression(event, 'report-field', entityName, returnType , valueExpression);

        // setCurrentEntityName(entityName);
        // setCurrentExpression(valueExpression);
        
        // setReportTableDefaultParameterIndex(-963);
        // setOpenEntityExpression(true);
      }  
   })

   const getFormatsList = (row: IReportField, cellId: keyof IReportField, 
      opts: {value: string, name: string}[]) => {        
      
      return getAsOptions(refEnumItems.current ?? [],Enum_REPORTFIELD_DATA_FORMAT);
    }

    const cellEditableReportField = (row: IReportField, cellId: keyof IReportField) => {
      return true;
    }
    const [headReportFieldCells, setHeadFieldCells]  = useState<HeadCell<IReportField>[]>([]);
    useEffect(() => {
      setHeadFieldCells([            
        {id:'name', label : t('Name'),  display: true, type: 'string', width: 40, isEditable: cellEditableReportField},
        {id:'format', label : t('Format'),  display: true, type: 'string', width: 25, isEditable: cellEditableReportField, 
          getOptions: getFormatsList },
        {id:'alias', label : t('Alias'),  display: true, type: 'string', width: 35, isEditable: cellEditableReportField,
          getInputAdornment: getInputAdornmentReportField}
            
        //{id:'extensionTypeDescription', label : t('Type description'),  display: true, type: 'string',} ,  
        //{id:'extensionTypeType', label : t('Type'),  display: true, type: 'string',} ,  
      ]  )
    }, [t,i18n])

    const refAppendReportFields = useRef<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateReportField = useRef<(index: number,value: Partial<FieldArray<IReport>> ) => void>(null);
    const refRemoveReportField = useRef<(index: number ) => void>(null);

    const handleAddReportFields = (event: any) => {
      (refAppendReportFields.current??emptyFunc)({ id: 0,  name: '', alias: '', format: 'exact' }); 
    }

    const [openEntityExpression, setOpenEntityExpression] = useState(false);
    const [currentExpression, setCurrentExpression] = useState('');  
    const [currentEntityName, setCurrentEntityName] = useState('');
    const [currentReturnType, setCurrentReturnType] = useState(''); 

    type ExpressionType = 'report-field' | 'file-name' | 'report-tableDefaultParameter' 
              | 'report-tableColumn' ;
    const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('report-field');

    const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
      entityName: string, returnType: string, expression: string) => {

    if(isFalsy(entityName)) {
      enqueueSnackbar( t('Entity is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
      return;
    }

    setCurrentExpressionType(expressionType);
    setCurrentEntityName(entityName);
    setCurrentReturnType(returnType);
    setCurrentExpression(expression);
    setOpenEntityExpression(true);
  }

    const handleClickOkExpression = async () => {

      const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);      
      if(!checkExpression){
        enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }
            
      const {syntaxOk, syntaxError, returnType} = checkExpression;
      if(!syntaxOk) {
        enqueueSnackbar( syntaxError , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      
      if(returnType !== currentReturnType) {
        enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
              return;
      }

      const {currentTableIndex} = getValues();

      if( ['report-field'].includes(currentExpressionType)  ) {
        const reportField = getValues().reportFields.at(currentFieldIndex);
        if(currentFieldIndex<0 || isFalsy(reportField)) return;

        (refUpdateReportField.current??emptyFunc)(currentFieldIndex, {...reportField, valueExpression: currentExpression} );
      } else if( ['file-name'].includes(currentExpressionType)  ) {
        const index = getValues().reportDocuments.findIndex(d => d.documentLanguage === watchCurrentDocumentLanguage);
        const reportDocument = getValues().reportDocuments.find(d => d.documentLanguage === watchCurrentDocumentLanguage);
        if(index < 0 || isFalsy(reportDocument)) return;

        updateReportDocument(index, {...reportDocument, messageFileName:currentExpression });
      } else if( ['report-tableDefaultParameter'].includes(currentExpressionType) ) {
        const reportTable = getValues().reportTables.at(currentTableIndex);
        if(currentTableIndex<0 || isFalsy(reportTable)) return;

        const reportTableDefaultParameter = reportTable.reportTableDefaultParameters.at(reportTableDefaultParameterIndex);
        if(reportTableDefaultParameterIndex<0 || isFalsy(reportTableDefaultParameter)) return;

        (refUpdateReportTableDefaultParameter.current??emptyFunc)(reportTableDefaultParameterIndex, 
          {...reportTableDefaultParameter, parameterValueExpression: currentExpression} );

      } else if( ['report-tableColumn'].includes(currentExpressionType) ) {
        
        const reportTable = getValues().reportTables.at(currentTableIndex); 
        if(currentTableIndex<0 || isFalsy(reportTable)) return;
        
        const reportTableColumn = reportTable.reportTableColumns.at(reportTableColumnIndex); 
        if(reportTableColumnIndex<0 || isFalsy(reportTableColumn)) return;
        
        (refUpdateReportTableColumn.current??emptyFunc)(reportTableColumnIndex, 
          {...reportTableColumn, defaultColumnValueExpression: currentExpression} );
      }

      
      // const enumItemFormat = enumItems.find(x => x.enumerationCode === Enum_REPORTFIELD_DATA_FORMAT && x.code === reportField.format);
            
      // if(returnType !== baseType(enumItemFormat?.parentEnumerationItemCode) ) {
      //   enqueueSnackbar( t('Selected format and return type of expression are incompatible') , { variant: 'error',
      //         anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
      //   return;
      // }

      // check expression syntax before update valueExpression.      
      //

      
      setOpenEntityExpression(false);
    }

    //const [reportTableIndex, setReportTableIndex] = useState<number>(-1);
    const reportTableRowActionIcon = ( reportTable: IReportTable) : ActionIconTableRow<IReport,IReportTable> => {  
      const res: ActionIconTableRow<IReport,IReportTable> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IReportTable) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IReportTable) => {           
          
          (refRemoveReportTable.current??emptyFunc)(index);            
        }
      }
      return res;
    }

    const getInputAdornmentReportTable = (row: IReportTable, cellId: keyof IReportTable)  => ({
 
      toolTip: 'Expression',
      icon: MoreIcon,
      onClickIcon: (event: any, index: number, row: IReportTable ) => {
       
               
        setValue('currentTableIndex', index);
        setOpenReportTableDetails(true);
      }  
   })

    const cellEditableReportTable = (row: IReportTable, cellId: keyof IReportTable) => {
      return true;
    }
    const [headReportTableCells, setHeadReportTableCells]  = useState<HeadCell<IReportTable>[]>([]);
    useEffect(() => {
      setHeadReportTableCells([            
        {id:'name', label : t('Name'),  display: true, type: 'string', width: 35, isEditable: cellEditableReportTable},
        // {id:'format', label : t('Format'),  display: true, type: 'string', width: 25, isEditable: cellEditableReportTable, 
        //   getOptions: getFormatsList },
        {id:'alias', label : t('Alias'),  display: true, type: 'string', width: 65, isEditable: cellEditableReportTable,
          getInputAdornment: getInputAdornmentReportTable}
            
        //{id:'extensionTypeDescription', label : t('Type description'),  display: true, type: 'string',} ,  
        //{id:'extensionTypeType', label : t('Type'),  display: true, type: 'string',} ,  
      ]  )
    }, [t,i18n])

    const refAppendReportTables = useRef<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateReportTable = useRef<(index: number,value: Partial<FieldArray<IReport>> ) => void>(null);
    const refRemoveReportTable = useRef<(index: number ) => void>(null);

    const handleAddReportTables = (event: any) => {
      (refAppendReportTables.current??emptyFunc)({ id: 0,  name: '', alias: '', reportId: _id, type: 'query',
        businessApplicationId: 0, businessApplicationName: '', 
        businessApplicationQueryId: 0, businessApplicationQueryName: '',
        entityName: '', entityDescription: '', entityEnumerableProperty: '', entityEnumerablePropertyDescription: '',
        reportTableColumns: [], reportTableDefaultParameters: [] }); 
    }

    const [reportTableColumnIndex, setReportTableColumnIndex] = useState<number>(-1);
    const reportTableColumnRowActionIcon = ( reportTable: IReportTableColumn) : ActionIconTableRow<IReport,IReportTableColumn> => {  
      const res: ActionIconTableRow<IReport,IReportTableColumn> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IReportTableColumn) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IReportTableColumn) => {           
          
          (refRemoveReportTableColumn.current??emptyFunc)(index);            
        }
      }
      return res;
    }

    const [reportTableType, setReportTableType] = useState<ReportTableType>('query');
    const handleChangeReportTableType = ( event: React.MouseEvent<HTMLElement>, newType: ReportTableType, rptTableIndex: number ) => {  
      
      if (newType === null) 
        return;
        
      const reportTable = getValues().reportTables.at(rptTableIndex);
      if( isFalsy(reportTable)) 
        return;
        
      (refUpdateReportTable.current??emptyFunc)(rptTableIndex, {...reportTable, type: newType} );   
            
    }

    const getReportTableColumnFormatsList = (row: IReportTableColumn, cellId: keyof IReportTableColumn, 
      opts: {value: string, name: string}[]) => {        
      
      return getAsOptions(refEnumItems.current ?? [],Enum_REPORTFIELD_DATA_FORMAT);
    }

    const getInputAdornmentColumnValueExpression = (row: IReportTableColumn, cellId: keyof IReportTableColumn)  => ({
 
      toolTip: 'Expression',
      icon: DeveloperModeIcon,
      onClickIcon: (event: any, index: number, row: IReportTableColumn ) => {
                          
        const {currentTableIndex} = getValues();
        const reportTable = getValues().reportTables.at(currentTableIndex); 
        
        if(isFalsy(reportTable))
          return;

        const {entityName} = reportTable!
        const reportTableColumn = reportTable.reportTableColumns.at(index)!;      
        
        const {defaultColumnValueExpression, format} = reportTableColumn;
           
        
        if(isFalsy(format)){
          enqueueSnackbar( t('Data format is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
        
        const enumItemFormat = (refEnumItems.current || []).find(x => x.enumerationCode === Enum_REPORTFIELD_DATA_FORMAT && x.code === format);
        
        const returnType = baseType(enumItemFormat?.parentEnumerationItemCode);

        setReportTableColumnIndex(index);
        handleClickOpenExpression(event, 'report-tableColumn', entityName, returnType, defaultColumnValueExpression);
              
        // setCurrentEntityName(entityName);
        // setReportTableColumnIndex(index);
        // setCurrentFieldIndex(-963963);        
        // setCurrentExpression(defaultColumnValueExpression);
        // setOpenEntityExpression(true); 
      }  
   })

    const cellEditableReportTableColumn = (row: IReportTableColumn, cellId: keyof IReportTableColumn) => {
      return true;
    }
    const [headReportTableColumnCells, setHeadReportTableColumnCells]  = useState<HeadCell<IReportTableColumn>[]>([]);
    useEffect(() => {
      setHeadReportTableColumnCells([            
        //{id:'name', label : t('Name'),  display: true, type: 'string', width: 50, isEditable: cellEditableReportTable},
        // {id:'format', label : t('Format'),  display: true, type: 'string', width: 25, isEditable: cellEditableReportTable, 
        //   getOptions: getFormatsList },
        {id:'alias', label : t('Alias'),  display: true, type: 'string', width: 35, isEditable: cellEditableReportTableColumn,
          },
        {id:'format', label : t('Format'),  display: true, type: 'string', width: 30, isEditable: cellEditableReportTableColumn, 
            getOptions: getReportTableColumnFormatsList }, 
        {id:'defaultColumnValueExpression', label : t('Default expression'),  display: true, type: 'string', width: 35, 
          getInputAdornment: getInputAdornmentColumnValueExpression },
              
        //{id:'extensionTypeDescription', label : t('Type description'),  display: true, type: 'string',} ,  
        //{id:'extensionTypeType', label : t('Type'),  display: true, type: 'string',} ,  
      ]  )
    }, [t,i18n])

    const refAppendReportTableColumns = useRef<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateReportTableColumn = useRef<(index: number,value: Partial<FieldArray<IReport>> ) => void>(null);
    const refRemoveReportTableColumn = useRef<(index: number ) => void>(null);

    const handleAddReportTableColumns = (event: MouseEvent<HTMLElement>) => {
      
      const {currentTableIndex} = getValues();
      // const alias = (inputColumnAlias.current) ?
      //                 (inputColumnAlias.current as HTMLInputElement).value : '';

      // if(alias === '') return;
      if(currentTableIndex < 0) return;

      const reportTable = getValues().reportTables.at(currentTableIndex);
      if(isFalsy(reportTable)) return;

      if(reportTable.reportTableColumns.some( col => col.alias === ''))
        return;

      (refAppendReportTableColumns.current??emptyFunc)({ id: 0, reportTableId: reportTable.id, 
              alias: '', format: 'exact' ,defaultColumnValueExpression: '' }); 
      // (inputColumnAlias.current! as HTMLInputElement).value = '';
    }

    const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);
    const inputBusinessApplication = useRef();

    const [isTableEntity, setIsTableEntity] = useState<boolean>(false);
    const handleClickSearchEntity = (event: any,tableEntity: boolean) => {
      setIsTableEntity(tableEntity);
      setOpenEntityFilter(true);
    }  

    const handleClickSearchApplicationQuery = (event: any) => {
      // setJobScheduleStepReportTableIndex(-963);
      setOpenApplicationQueryFilter(true);
     }

     const handleClickSearchEnumerableProperty = (event: any) => {
      // setJobScheduleStepReportTableIndex(-963);
      setOpenEnumerablePropertyFilter(true);
     }

    const handleClickSearchQueryFeature = (event: any) => {
      // setJobScheduleStepReportTableIndex(-963);
      setOpenQueryFeatureFilter(true);
     }

     const [queryFeatureInnerObjectTypeProperties, setQueryFeatureInnerObjectTypeProperties] = useState<IEntityProperty[]>([])
     const [openInnerObjectTypePropertiesByQueryFeature, setOpenInnerObjectTypePropertiesByQueryFeature] = useState<boolean>(false);
     const handleClickQueryFeatureInnerObject = async (entityName: string, queryFeatureName: string) => {

      const arr = await getQueryFeatureInnerObjectProperties(entityName, queryFeatureName);
      setQueryFeatureInnerObjectTypeProperties(arr);
      setOpenInnerObjectTypePropertiesByQueryFeature(true);
     } 
     

    const handleClickOpenFileNameExpression = (event: any, fileName : string) => {

      const {entityName} = getValues();

      handleClickOpenExpression(event, 'file-name', entityName, 'string', fileName );

      // setCurrentEntityName(entityName);
      // setCurrentExpression(fileName);
      // setCurrentFieldIndex(-996633);
      // setOpenEntityExpression(true);
    }

    const [openReportTableDetails,setOpenReportTableDetails] = useState<boolean>(false);   

    const refAppendReportTableDefaultParameters = useRef<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateReportTableDefaultParameter = useRef<(index: number,value: Partial<FieldArray<IReport>> ) => void>(null);
    const refRemoveReportTableDefaultParameter = useRef<(index: number ) => void>(null);

    const [reportTableDefaultParameterIndex, setReportTableDefaultParameterIndex] = useState<number>(-1);

    const reportTableDefaultParameterRowActionIcon = ( reportTableDefaultParameter: IReportTableDefaultParameter) : ActionIconTableRow<IReport,IReportTableDefaultParameter> => {  
      const res: ActionIconTableRow<IReport,IReportTableDefaultParameter> = {
        toolTip: 'Expression',
        icon: DeveloperModeIcon,
        hasAction: (index: number,row: IReportTableDefaultParameter) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IReportTableDefaultParameter) => {    
                 
          const {parameterValueExpression} = row;
          const {currentTableIndex} = getValues();

          const reportTable = getValues().reportTables.at(currentTableIndex);
          if(isFalsy(reportTable))
            return;

          const {entityName} = reportTable!;
          
          setReportTableDefaultParameterIndex(index);
          const returnType = '';
          handleClickOpenExpression(event, 'report-tableDefaultParameter', entityName, returnType, parameterValueExpression);

          // setCurrentEntityName(entityName);
          
          // setCurrentFieldIndex(-993366);
          
          // setCurrentExpression(row.parameterValueExpression);  
          // setOpenEntityExpression(true); 
                   
        }
      }
      return res;
    }
    
    const [headReportTableDefaultParameterCells, setHeadReportTableDefaultParameterCells]  = useState<HeadCell<IReportTableDefaultParameter>[]>([]);
      useEffect(() => {
        setHeadReportTableDefaultParameterCells([            
          {id:'parameterName', label : t('Name'),  display: true, type: 'string', width: 30, },
          //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
          {id:'parameterValueExpression', label : t('Expression'),  display: true, type: 'string', width: 70, }
        ])
      }, [t,i18n]);

    const inputColumnAlias = useRef();

    // const [reportType, setReportType] = useState<ReportType>('message');
    // const handleChangeReportType = ( event: React.MouseEvent<HTMLElement>, newType: ReportType ) => {  

    //   console.log(newType);
    //   if (newType !== null) {
    //     setReportType(newType);
    //     setValue('type', newType);
    //   }      
    // }

    
    useEffect( () => {

      if(isFalsy(watchCurrentDocumentLanguage)) return;
           
      const reportDocument = getValues().reportDocuments.find(d => d.documentLanguage === watchCurrentDocumentLanguage);
      
      if(isFalsy(reportDocument)){         
        
        appendReportDocuments( {...defaultReportDocument, documentLanguage:watchCurrentDocumentLanguage });
      }      
      
    }, [ watchCurrentDocumentLanguage])

    function openFileDialog(  ) {
      (document as any).getElementById("file-upload").click();
      // const fileElementId = "documentFile";
      // var element = (document as any).getElementById(fileElementId);

      // if(!isFalsy(element))
      //   element.click();
    }

    const setFile = (_event: any, docLanguage: string) => {
      let f = _event.target.files![0];

      if(getValues().name.trim() === '' || getValues().description.trim() === '') {
        enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
        return;
      }             
      
      const fnName = sanitizeFileName(getValues().name).toLowerCase();
      const fnDescription = sanitizeFileName(getValues().description).toLowerCase();      

      if( !f.name.trim().toLowerCase().includes(docLanguage.toLowerCase()) || 
          (!f.name.toLowerCase().includes(fnName) && !f.name.toLowerCase().includes(fnDescription))   ) {
        enqueueSnackbar( `${t('The file name is not valid')} : 
              ${fnName} / ${fnDescription} - ${docLanguage}`, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 
        return;
      }

      var reader = new FileReader();
      
      reader.onload = function () {

        var binaryString = reader.result as string;

        const base64String = binaryString
                                    .replace('data:', '')
                                    .replace(/^.+,/, '');

        const index = getValues().reportDocuments.findIndex(d => d.documentLanguage === docLanguage);
        if(index < 0) return;

        const reportDocument = getValues().reportDocuments.at(index)!;

        updateReportDocument(index, {...reportDocument, base64DocumentFile: base64String, documentFileName: f.name });
      };

      reader.onerror = function () {
        console.log("File load failed");
      };
  
      reader.readAsDataURL(f);
    }


    const refAnchorElement = useRef<HTMLAnchorElement | null>(null);
    const handleDocumentDownload = async (event: any) => {
      const {id} = getValues();
      
      const {fileName, fileToken} = await retriveReportDocumentFile(id, watchCurrentDocumentLanguage);      
      
      if(isFalsy(refAnchorElement.current)) return;

      refAnchorElement.current.href = `${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`;
      refAnchorElement?.current?.click();
    }

    // const [anchorElMessageMenu, setAnchorElMessageMenu] = useState(null);
    // const openMessageMenu = Boolean(anchorElMessageMenu);

    // const handleMessageMenuClick = (event : any) => {  
      
    //   if(_isMessageMenuItemClick) {
    //     _isMessageMenuItemClick = false;
    //     return;
    //   }        
    //   setAnchorElMessageMenu(event.currentTarget);
    // }
  
    // let _isMessageMenuItemClick: boolean = false;
    // const handleMessageMenuItemClick = (messageType: MessageType) => {
    //   _isMessageMenuItemClick = true;
    //   setValue('currentMessageType', messageType);
    //   setAnchorElMessageMenu(null);      
    // }

    // const [anchorElDocumentMenu, setAnchorElDocumentMenu] = useState(null);
    // const openDocumentMenu = Boolean(anchorElDocumentMenu);

    // const handleDocumentMenuClick = (event : any) => {  
    //   if(_isDocumentMenuItemClick) {
    //     _isDocumentMenuItemClick = false;
    //     return;
    //   }        
    //   setAnchorElDocumentMenu(event.currentTarget);
    // }
  
    // let _isDocumentMenuItemClick: boolean = false;
    // const handleDocumentMenuItemClick = (documentType: ReportFileType) => {
    //   _isDocumentMenuItemClick = true;
    //   setValue('currentReportFileType', documentType);
    //   setAnchorElDocumentMenu(null);      
    // }

    
  
    const refEnumItems = useRef<IEnumerationItem[]>();

    useEffect( () => {
      async function loadEnumerationItems() {
        const arr = await getEnumerationItemsByEnumerationCodes( [Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] );
        setEnumItems([...arr]);

        refEnumItems.current = arr;
      }

      loadEnumerationItems();
    }, [])

      useEffect( () => {        
        setCurrentFormNameAtom(t('Report'));
        setCurrentBasicTextFilterProps(basicFilterReport);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Report',_id, refetch);  
          }, [_id] );
    
    
      useEffect( () => {
         
        if(_data && _data.id > 0) {
            
            reset({..._data,            
              currentDocumentLanguage: _data.reportDocuments.length ? _data.reportDocuments[0].documentLanguage : 'fr-FR',
            });
            
          }          
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
        _setId(0);       
        reset(defaultReport);    

        if(!isFalsy(watchCurrentDocumentLanguage)){
          appendReportDocuments( {...defaultReportDocument, documentLanguage:watchCurrentDocumentLanguage });
        }
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
        
        if(!checkEntitySaveAuthorization('Report', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
            }          
          mutate( {...data});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Report', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {       

      queryClient.invalidateQueries(['Report',_id]);        
      await retrieveData('Report',_id, refetch);        
      reset(_data);           
    }

    

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                    label={`${t('Direct ?')}`}
                                    control={
                                    <Controller
                                        name='isDirectReport'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} /> 
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                           </Box>
                        </Stack>                        
                    </Grid>     
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="entityDescription" label={t('Entity')} 
                                  {...register('entityDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) => handleClickSearchEntity(event,false)}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                           </Box>
                           { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFilter(false);}} onOk={()=> {setOpenEntityFilter(false);}}  >
                                    <BasicTextFilterForm<IEntity> {...basicFilterEntity} />
                                  </FormDialog>  }
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={12} lg={7} component={Paper} >
                      <Stack flexDirection='column'>                        
                          <Box sx={{ mt: 1, width: '100%', pt: 3 }} >
                            <Stack flexDirection='row'>
                              <Box sx={{ mt: 1, width: '35%', pt: 0 }} >
                                <Controller name='currentDocumentLanguage' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="currentDocumentLanguage"
                                        label={t('Document language')} >
                                          <MenuItem  value="fr-FR">{t('French')}</MenuItem>
                                          <MenuItem  value="en-US">{t('English')}</MenuItem>
                                      </TextField>
                                    )}
                                />
                              </Box>
                              <Box sx={{ mt: 1, width: '60%', pt: 0 }} >                                                                
                                {
                                  getValues().reportDocuments.map( (reportDocument, index) => {
                                    const { documentLanguage, messageObject, messageBody, shortMessageBody, documentFileName} = reportDocument;
                                      return (documentLanguage === watchCurrentDocumentLanguage) && (
                                        <Button onClick={ () => openFileDialog()} key={`lg - ${documentLanguage} ${index}`} id={`lg - ${documentLanguage} ${index}`}>
                                            <input type="file" accept=".rpx,.docx" id="file-upload" style={{ display: "none" }} onChange={ (_event) => setFile(_event,documentLanguage) }/>
                                            {isFalsy(reportDocument.documentFileName) ? `${t('Click to toad file')} ...`: `${reportDocument.documentFileName} -> ${t('Click to change')}`}
                                        </Button>)
                                   })
                                }                              
                              </Box>
                              <Box sx={{ ...justifyCenter, mt: 1, width: '5%', pt: 0 }} >
                                <Button>
                                  <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                    {`: :`}
                                  </Typography>
                                  <Box sx={{ ...justifyCenter, ml: 1 }} >                                                     
                                    <GrDocumentDownload size={24} onClick={handleDocumentDownload} />
                                  </Box>  
                                </Button>
                                <a href={`${globalConfig.get().apiUrl}/Download/${'token'}/${'name'}`} ref={refAnchorElement} id="fileDownload" className="hidden" target="__blank" download/>
                              </Box>
                            </Stack>    
                          </Box>                                              
                          {
                             getValues().reportDocuments.map( (reportDocument, index) => {
                              
                              const { documentLanguage, messageObject, messageBody, shortMessageBody,messageFileName, documentFileName} = reportDocument;
                              
                              return (documentLanguage === watchCurrentDocumentLanguage) && (
                              <Fragment key={`key-${index} - ${reportDocument.documentLanguage} - ${reportDocument.messageObject} - ${reportDocument.messageBody} - ${reportDocument.shortMessageBody}`}>
                                  <Box sx={{ mt: 1, width: '100%' }} >                                
                                    <Controller
                                        key={`query-${messageFileName}-query-x`}
                                        render={({ field }) => <TextField label={t('File name')} {...field} multiline={true} rows={2}  
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`, textTransform: 'none'}} 
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={ (event) => handleClickOpenFileNameExpression(event, messageFileName)}>
                                                  <DeveloperModeIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }}
                                           />} 
                                        name={`reportDocuments.${index}.messageFileName`}                      
                                        control={control}
                                      />
                                  </Box>

                                  <Box sx={{ mt: 1, width: '100%' }} >                                
                                    <Controller
                                        key={`query-${shortMessageBody}-query-x`}
                                        render={({ field }) => <TextField label={t('Short message')} {...field} multiline={true} rows={2}  
                                          inputRef={expressionRefShortMessage} onFocus={(event) => handleInputFocus(event, 'shortMessageBody', index)}
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`, textTransform: 'none'}} 
                                          
                                           />} 
                                        name={`reportDocuments.${index}.shortMessageBody`}                      
                                        control={control}
                                      />
                                  </Box>
                                  
                                  <Box sx={{ mt: 1, width: '100%' }} >                                
                                    <Controller
                                        key={`query-${messageObject}-query-x`}
                                        render={({ field }) => <TextField label={t('Email object')} {...field} multiline={true} rows={2} 
                                          inputRef={expressionRefMessageObject} onFocus={(event) => handleInputFocus(event, 'messageObject', index)}
                                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          sx={{width:`calc(100% - 8px)`, textTransform: 'none'}} 
                                           />} 
                                        name={`reportDocuments.${index}.messageObject`}                      
                                        control={control}
                                      />
                                  </Box>
                                
                                
                                  <Box sx={{ mt: 1, width: '100%' }} id={`reportDocuments.${index}.messageBody`} > 
                                    <Controller

                                        render={({ field }) => 
                                          <RichWysiwygEditor {...field} 
                                            mentionSuggestions={getValues().reportFields.map(({alias}) => ({text: alias, value: "${"+alias+"}"}))}
                                            id={`key-${index} - ${reportDocument.documentLanguage} - ${reportDocument.messageBody}`} />}
                                            name={ `reportDocuments.${index}.messageBody` }
                                            control={control}
                                            defaultValue=""
                                            
                                            rules={{
                                              validate: {
                                                required: (v) =>
                                                  (v && stripHtml(v).result.length > 0) ||
                                                  "Description is required",
                                                maxLength: (v) =>
                                                  (v && stripHtml(v).result.length <= 2000) ||
                                                  "Maximum character limit is 2000",
                                              },
                                            }}
                                      />                                                      
                                  </Box>                                
                                
                              </Fragment> )})
                        }
                      </Stack>   
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} key={`reportTables-${getValues().id}-${getValues().reportTables.length}`}>
                           <ArrayFieldTableEx<IReport,IReportTable,'id'> 
                                key={`Report table - table`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headReportTableCells} rowsPathName='reportTables' 
                                title={t(`Report tables`)} rowActionIcon={reportTableRowActionIcon}  
                                //onRowDoubleClick={handleAliasDoubleClick}
                                //onRowSelected={handleRoleEntitySelected}                                                    
                                refAppend={refAppendReportTables as MutableRefObject<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateReportTable as MutableRefObject<(index: number,value: Partial<FieldArray<IReport>>) => void>}
                                refRemove={refRemoveReportTable as MutableRefObject<(index: number) => void>}
                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddReportTables ,icon: AddCircleIcon, },
                                ]}
                            /> 
                            {
                              <FormDialog open={openReportTableDetails} maxWidth='md' 
                                okText={t('OK')} cancelText='' title={`${t('Table details')} >> ${getValues().currentTableIndex+1}`} onCancel={()=> {}} 
                                onClose={()=> {setOpenReportTableDetails(false);}} 
                                onOk={()=> {setOpenReportTableDetails(false);}} 
                                key={`reportTables-${getValues().id}-${getValues().reportTables.length}`} >
                                <Stack flexDirection='column'  >
                                { 
                                getValues().reportTables.map( (reportTable, index) => {
                                  const {type} = reportTable;
                                  return (index === getValues().currentTableIndex) && (
                                <Fragment key={`key- ${reportTable.type} - 0.0 ${index} - ${getValues().reportTables.length}`}>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                      <ToggleButtonGroup size="small"
                                        color="primary"
                                        value={type}
                                        exclusive
                                        onChange={ (event, newType) => handleChangeReportTableType(event, newType, getValues().currentTableIndex)}
                                        aria-label="Small sizes" fullWidth
                                      >
                                        <ToggleButton value="query">{t('Query')}</ToggleButton>
                                        <ToggleButton value="property">{t('Property')}</ToggleButton>
                                        <ToggleButton value="feature">{t('Feature')}</ToggleButton>
                                      </ToggleButtonGroup> 
                                  </Box>
                                  {type === 'query' && <>                                  
                                      <Box sx={{ mt: 1, width: '100%' }} key={`key -- ${type}`} >        
                                             
                                        <Controller 
                                            key={`key - Query ${getValues().id}-${reportTable.applicationQueryName}`}                                        
                                            control={control}
                                            render={({ field }) => <TextField label={t('Query filter')} 
                                              id={`Query ${index} ${reportTable.applicationQueryName}` }
                                              key={`key - ${getValues().id}-${reportTable.applicationQueryName}`}
                                              {...field} sx={{width:`calc(50% - 8px)`}} 
                                              inputProps={ {readOnly: true }} 
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">                                            
                                                    <IconButton color="primary" onClick={handleClickSearchApplicationQuery}>
                                                      <ArrowDropDownCircleIcon />
                                                    </IconButton>                                                                                               
                                                  </InputAdornment>
                                                )
                                              }}
                                          />}
                                          name={`reportTables.${getValues().currentTableIndex}.applicationQueryName`}
                                        />
                                        <Controller 
                                            key={`key - Entity ${getValues().id}-${reportTable.entityName}`}                                        
                                            control={control}
                                            render={({ field }) => <TextField label={t('Entity')} 
                                              id={`${getValues().id}-${reportTable.entityName}`}       
                                              key={`key - ${getValues().id}-${reportTable.entityName}`}                                       
                                              {...field} sx={{width:`calc(50% - 8px)`}} 
                                              inputProps={ {readOnly: true }} 
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">                                            
                                                    <IconButton color="primary" onClick={(event) => handleClickSearchEntity(event,true)}>
                                                      <ArrowDropDownCircleIcon />
                                                    </IconButton>                                                                                               
                                                  </InputAdornment>
                                                )
                                              }}
                                        />}
                                            name={`reportTables.${getValues().currentTableIndex}.entityName`}
                                          />
                                        { openApplicationQueryFilter && <FormDialog open={openApplicationQueryFilter} maxWidth='md'
                                          okText={t('OK')} cancelText='' title={t('Query filter')} onCancel={()=> {}} 
                                          onClose={()=> {setOpenApplicationQueryFilter(false);}} onOk={()=> {setOpenApplicationQueryFilter(false);}}  >
                                          <BasicTextFilterForm<IApplicationQuery> {...basicFilterApplicationQuery} />
                                        </FormDialog>  }
                                      </Box> 
                                      
                                      <Box sx={{ mt: 1, width: '100%' }} key={`reportFields-${reportTable.name}-${getValues().currentTableIndex} ${reportTable.reportTableDefaultParameters.length}`}>
                                        <ArrayFieldTableEx<IReport,IReportTableDefaultParameter,'id'> 
                                              key={`Report field - IReportTableDefaultParameter ${reportTable.name}`}
                                              mainObject={getValues()} fieldKey='id' 
                                              headCells={headReportTableDefaultParameterCells} 
                                              rowsPathName={`reportTables.${getValues().currentTableIndex}.reportTableDefaultParameters`}  
                                              title={t(`SQL Query parameters`)} rowActionIcon={reportTableDefaultParameterRowActionIcon}  
                                              
                                              //onRowSelected={handleRoleEntitySelected}                                                    
                                              refAppend={refAppendReportTableDefaultParameters as MutableRefObject<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>}
                                              refUpdate={refUpdateReportTableDefaultParameter as MutableRefObject<(index: number,value: Partial<FieldArray<IReport>>) => void>}
                                              refRemove={refRemoveReportTableDefaultParameter as MutableRefObject<(index: number) => void>}
                                              //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                              toolbarActions={[
                                                //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddReportTableColumns ,icon: AddCircleIcon, },
                                            ]}
                                          />                                                                 
                                      </Box>
                                    </>
                                  }
                                  {type === 'property' && <Box sx={{ mt: 1, width: '100%' }} key={`key -- ${reportTable.type}`}>
                                    <Controller 
                                          key={`key - ${getValues().id}-${reportTable.entityName} -  ${reportTable.type} `}                                        
                                          control={control}
                                          render={({ field }) => <TextField label={t('Property')}
                                            {...field} sx={{width:`calc(100% - 8px)`}} 
                                            inputProps={ {readOnly: true }} 
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">                                            
                                                  <IconButton color="primary" onClick={(event) => handleClickSearchEnumerableProperty(event)}>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton>                                                                                               
                                                </InputAdornment>
                                              )
                                            }}
                                        />}
                                        name={`reportTables.${getValues().currentTableIndex}.entityEnumerablePropertyDescription`}
                                      />
                                    </Box>
                                  }
                                  {type === 'feature' && <Box sx={{ mt: 1, width: '100%' }} key={`key -- ${reportTable.type}`}>
                                    <Controller 
                                          key={`key - ${getValues().id}-${reportTable.queryFeatureDescription} -  ${reportTable.type} `}                                        
                                          control={control}
                                          render={({ field }) => <TextField label={t('Feature')} key={`${reportTable.queryFeatureDescription}`}
                                            {...field} sx={{width:`calc(100% - 8px)`}} 
                                            inputProps={ {readOnly: true }} 
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">                                            
                                                  <IconButton color="primary" onClick={(event) => handleClickSearchQueryFeature(event)}>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton>                                                                                               
                                                </InputAdornment>
                                              )
                                            }}
                                            helperText={
                                              <Typography  sx={{display: 'flex', alignItems: 'center', marginTop: 0.25}} noWrap >
                                                {`${t(('Item'))} :((${reportTable.innerObjectPublicNamespace}.${reportTable.innerObjectTypeName})InnerObject).xxx `}
                                                <Link  component='button' variant='body2' 
                                                    onClick={(event) => handleClickQueryFeatureInnerObject(getValues().entityName, reportTable.queryFeatureName )}
                                                    sx={{cursor: 'pointer', textDecoration: 'underline', marginLeft: 1}} >
                                                  {`${t(('Properties'))}`}                                                
                                                </Link>
                                              </Typography> 
                                            }
                                        />}
                                        name={`reportTables.${getValues().currentTableIndex}.queryFeatureDescription`}
                                      />
                                    </Box>
                                  }
                                  { openEnumerablePropertyFilter && <FormDialog open={openEnumerablePropertyFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Property filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEnumerablePropertyFilter(false);}} onOk={()=> {setOpenEnumerablePropertyFilter(false);}}  >
                                    <BasicTextFilterForm<IEntityProperty> {...basicFilterEnumerableProperty} />
                                  </FormDialog>  } 
                                  { openInnerObjectTypePropertiesByQueryFeature && <FormDialog open={openInnerObjectTypePropertiesByQueryFeature} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Properties')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenInnerObjectTypePropertiesByQueryFeature(false);}} 
                                    onOk={()=> {setOpenInnerObjectTypePropertiesByQueryFeature(false);}}  >
                                      <Stack flexDirection='column'>
                                        <Box sx={{ mt: 1, width: '100%' }} >
                                          <EnhancedTable<IEntityProperty> rows={queryFeatureInnerObjectTypeProperties || []} 
                                            headCells={[            
                                              {id:'name', label : t('Name'),  display: true, type: 'string', },
                                              {id:'description', label : t('Description'),  display: true, type: 'string', },
                                              
                                            ]} 
                                            title={`${t('Properties')}`} objKey='name' 
                                            stateSelected={undefined} 
                                            onRowSelected={undefined} rowCheckedMode='single'
                                            onRowCheckedSelectChange={undefined} order='desc' orderBy='name'
                                            onRowDoubleClick={undefined} 
                                            rowActionIcon={undefined}
                                            toolbarActions={[
                                              // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                            ]}
                                          />                           
                                        </Box>
                                      </Stack>
                                  </FormDialog>  }    
                                  { openQueryFeatureFilter && <FormDialog open={openQueryFeatureFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Query method feature filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenQueryFeatureFilter(false);}} onOk={()=> {setOpenQueryFeatureFilter(false);}}  >
                                    <BasicTextFilterForm<IQueryFeatureDescription> {...basicFilterQueryFeature} />
                                  </FormDialog>  }                             
                                  <Box sx={{ mt: 1, width: '100%' }} key={`reportFields-${getValues().id}-${getValues().reportTables[getValues().currentTableIndex].reportTableColumns.map(c => c.alias).join('-')}`}>
                                    <ArrayFieldTableEx<IReport,IReportTableColumn,'id'> 
                                          key={`Report field - IReportTableColumn ${reportTable.name} ${reportTable.reportTableColumns.map(c => c.alias).join('-')}`}
                                          mainObject={getValues()} fieldKey='id' 
                                          headCells={headReportTableColumnCells} 
                                          rowsPathName={`reportTables.${getValues().currentTableIndex}.reportTableColumns`}  
                                          title={t(`Column of table (alias)`)} rowActionIcon={reportTableColumnRowActionIcon}  
                                          
                                          //onRowSelected={handleRoleEntitySelected}                                                    
                                          refAppend={refAppendReportTableColumns as MutableRefObject<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>}
                                          refUpdate={refUpdateReportTableColumn as MutableRefObject<(index: number,value: Partial<FieldArray<IReport>>) => void>}
                                          refRemove={refRemoveReportTableColumn as MutableRefObject<(index: number) => void>}
                                          //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                          toolbarActions={[
                                            { toolTip: `${t('Add')}...`, onClickIcon: handleAddReportTableColumns ,icon: AddCircleIcon, },
                                        ]}
                                      />                                                                 
                                  </Box> 
                                  
                                </Fragment> ) })
                                }
                                </Stack>
                              </FormDialog>
                            }                       
                        </Box>
                        <Box sx={{ mt: 8, width: '100%' }} key={`reportFields-${getValues().id}-${getValues().reportFields.length}`}>
                          <ArrayFieldTableEx<IReport,IReportField,'id'> 
                                key={`Report field -`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headReportFieldCells} rowsPathName='reportFields' 
                                title={t(`Items list`)} rowActionIcon={reportFieldRowActionIcon}  
                                onRowDoubleClick={handleAliasDoubleClick}
                                //onRowSelected={handleRoleEntitySelected}                                                    
                                refAppend={refAppendReportFields as MutableRefObject<(value: Partial<FieldArray<IReport>> | Partial<FieldArray<IReport>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateReportField as MutableRefObject<(index: number,value: Partial<FieldArray<IReport>>) => void>}
                                refRemove={refRemoveReportField as MutableRefObject<(index: number) => void>}
                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddReportFields ,icon: AddCircleIcon, },
                                ]}
                            /> 
                            { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Expression')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }                           
                        </Box>
                      </Stack>   
                    </Grid>      
                                              
                </Grid>
            </Box>
        </FormProvider> 
  )
}

